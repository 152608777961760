import { default as b2bumexSVeqiLMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/b2b.vue?macro=true";
import { default as _91id_9368w1Gald3zMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/business/profile/[id].vue?macro=true";
import { default as list2CzGwkiQDhMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/follow/list.vue?macro=true";
import { default as indexLfCn7j1EsVMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/index.vue?macro=true";
import { default as bookmarkVW9dIVRTvCMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/interest/bookmark.vue?macro=true";
import { default as followingh0J7IItGBOMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/interest/following.vue?macro=true";
import { default as interestnZhxbwGUXcMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/interest.vue?macro=true";
import { default as confirm3HZJjqzPGuMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/manager/invite/confirm.vue?macro=true";
import { default as indexkquUDhIcluMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/manager/invite/index.vue?macro=true";
import { default as joinMPzNUCbyVjMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/manager/invite/join.vue?macro=true";
import { default as callbackCertifyFeiLiq2obBMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/member/auth/callbackCertify.vue?macro=true";
import { default as callbackOauthe45Bju3UYIMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/member/auth/callbackOauth.vue?macro=true";
import { default as completejUJbCWROJgMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/member/join/complete.vue?macro=true";
import { default as email3WBaeBCYzvMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/member/join/email.vue?macro=true";
import { default as index3G4GzuS7Q8Meta } from "/apps/src/ound-front/source/apps/shop-web/pages/member/join/index.vue?macro=true";
import { default as profilezrI4Vl0VtWMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/member/join/profile.vue?macro=true";
import { default as emailJocZfm51YIMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/member/login/email.vue?macro=true";
import { default as indexuY4i9eeyHpMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/member/login/index.vue?macro=true";
import { default as index6RKmWJZkFaMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/mobile/category/index.vue?macro=true";
import { default as contentstRdDtcxSf7Meta } from "/apps/src/ound-front/source/apps/shop-web/pages/mypage/business/analytics/contents.vue?macro=true";
import { default as outline11iBCEwuPRMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/mypage/business/analytics/outline.vue?macro=true";
import { default as indexjOkFAE8lztMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/mypage/business/index.vue?macro=true";
import { default as profileE9R8EI1y3zMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/mypage/business/profile.vue?macro=true";
import { default as registerxaERyz8R2ZMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/mypage/content/register.vue?macro=true";
import { default as indexk9dOmH8ZbmMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/mypage/index.vue?macro=true";
import { default as registerWl36uiCJ8BMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/mypage/media-publish/register.vue?macro=true";
import { default as changeKpASDC9fdkMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/mypage/password/change.vue?macro=true";
import { default as check2gtCSHO5naMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/mypage/password/check.vue?macro=true";
import { default as editcYVxQMm4HOMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/mypage/user/edit.vue?macro=true";
import { default as withdrawAWAiRg5iHuMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/mypage/user/withdraw.vue?macro=true";
import { default as emailG8KSaNeTfIMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/password/find/email.vue?macro=true";
import { default as optionshLLTmiVxcXMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/password/find/options.vue?macro=true";
import { default as phoneK6ny4Y637NMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/password/find/phone.vue?macro=true";
import { default as _91type_933AjJOJTOeAMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/password/reset/[type].vue?macro=true";
import { default as _91id_939L3NiTRXZeMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/post/[id].vue?macro=true";
import { default as _91id_93iZ4gMTjYLVMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/post/category/list/[id].vue?macro=true";
import { default as listnbBQuCXzQaMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/post/interest/list.vue?macro=true";
import { default as listTtUJ7hEbliMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/post/latest/list.vue?macro=true";
import { default as listfZ5IM3tGxWMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/post/popular/list.vue?macro=true";
import { default as _91id_93nZSqGqFPGPMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/post/recommend/list/[id].vue?macro=true";
import { default as listVaJe10HzxGMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/post/report/list.vue?macro=true";
import { default as chartFdxWoVOXvUMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/sample/chart.vue?macro=true";
import { default as modalUG4QBxy0CjMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/sample/modal.vue?macro=true";
import { default as page1ljFJjkFSzMMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/sample/page1.vue?macro=true";
import { default as page26FxOgkzU0CMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/sample/page2.vue?macro=true";
import { default as page4_errorWuwXEWLKovMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/sample/page4_error.vue?macro=true";
import { default as unsplashH3F4iIfKf4Meta } from "/apps/src/ound-front/source/apps/shop-web/pages/sample/unsplash.vue?macro=true";
import { default as business5HmZ09vJONMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/search/business.vue?macro=true";
import { default as contentsn5jIdKkuZgMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/search/contents.vue?macro=true";
import { default as searcht3wAo3yyVxMeta } from "/apps/src/ound-front/source/apps/shop-web/pages/search.vue?macro=true";
export default [
  {
    name: b2bumexSVeqiLMeta?.name ?? "b2b",
    path: b2bumexSVeqiLMeta?.path ?? "/b2b",
    meta: b2bumexSVeqiLMeta || {},
    alias: b2bumexSVeqiLMeta?.alias || [],
    redirect: b2bumexSVeqiLMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/b2b.vue").then(m => m.default || m)
  },
  {
    name: _91id_9368w1Gald3zMeta?.name ?? "business-profile-id",
    path: _91id_9368w1Gald3zMeta?.path ?? "/business/profile/:id()",
    meta: _91id_9368w1Gald3zMeta || {},
    alias: _91id_9368w1Gald3zMeta?.alias || [],
    redirect: _91id_9368w1Gald3zMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/business/profile/[id].vue").then(m => m.default || m)
  },
  {
    name: list2CzGwkiQDhMeta?.name ?? "follow-list",
    path: list2CzGwkiQDhMeta?.path ?? "/follow/list",
    meta: list2CzGwkiQDhMeta || {},
    alias: list2CzGwkiQDhMeta?.alias || [],
    redirect: list2CzGwkiQDhMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/follow/list.vue").then(m => m.default || m)
  },
  {
    name: indexLfCn7j1EsVMeta?.name ?? "index",
    path: indexLfCn7j1EsVMeta?.path ?? "/",
    meta: indexLfCn7j1EsVMeta || {},
    alias: indexLfCn7j1EsVMeta?.alias || [],
    redirect: indexLfCn7j1EsVMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: interestnZhxbwGUXcMeta?.name ?? "interest",
    path: interestnZhxbwGUXcMeta?.path ?? "/interest",
    meta: interestnZhxbwGUXcMeta || {},
    alias: interestnZhxbwGUXcMeta?.alias || [],
    redirect: interestnZhxbwGUXcMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/interest.vue").then(m => m.default || m),
    children: [
  {
    name: bookmarkVW9dIVRTvCMeta?.name ?? "interest-bookmark",
    path: bookmarkVW9dIVRTvCMeta?.path ?? "bookmark",
    meta: bookmarkVW9dIVRTvCMeta || {},
    alias: bookmarkVW9dIVRTvCMeta?.alias || [],
    redirect: bookmarkVW9dIVRTvCMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/interest/bookmark.vue").then(m => m.default || m)
  },
  {
    name: followingh0J7IItGBOMeta?.name ?? "interest-following",
    path: followingh0J7IItGBOMeta?.path ?? "following",
    meta: followingh0J7IItGBOMeta || {},
    alias: followingh0J7IItGBOMeta?.alias || [],
    redirect: followingh0J7IItGBOMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/interest/following.vue").then(m => m.default || m)
  }
]
  },
  {
    name: confirm3HZJjqzPGuMeta?.name ?? "manager-invite-confirm",
    path: confirm3HZJjqzPGuMeta?.path ?? "/manager/invite/confirm",
    meta: confirm3HZJjqzPGuMeta || {},
    alias: confirm3HZJjqzPGuMeta?.alias || [],
    redirect: confirm3HZJjqzPGuMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/manager/invite/confirm.vue").then(m => m.default || m)
  },
  {
    name: indexkquUDhIcluMeta?.name ?? "manager-invite",
    path: indexkquUDhIcluMeta?.path ?? "/manager/invite",
    meta: indexkquUDhIcluMeta || {},
    alias: indexkquUDhIcluMeta?.alias || [],
    redirect: indexkquUDhIcluMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/manager/invite/index.vue").then(m => m.default || m)
  },
  {
    name: joinMPzNUCbyVjMeta?.name ?? "manager-invite-join",
    path: joinMPzNUCbyVjMeta?.path ?? "/manager/invite/join",
    meta: joinMPzNUCbyVjMeta || {},
    alias: joinMPzNUCbyVjMeta?.alias || [],
    redirect: joinMPzNUCbyVjMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/manager/invite/join.vue").then(m => m.default || m)
  },
  {
    name: callbackCertifyFeiLiq2obBMeta?.name ?? "member-auth-callbackCertify",
    path: callbackCertifyFeiLiq2obBMeta?.path ?? "/member/auth/callbackCertify",
    meta: callbackCertifyFeiLiq2obBMeta || {},
    alias: callbackCertifyFeiLiq2obBMeta?.alias || [],
    redirect: callbackCertifyFeiLiq2obBMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/member/auth/callbackCertify.vue").then(m => m.default || m)
  },
  {
    name: callbackOauthe45Bju3UYIMeta?.name ?? "member-auth-callbackOauth",
    path: callbackOauthe45Bju3UYIMeta?.path ?? "/member/auth/callbackOauth",
    meta: callbackOauthe45Bju3UYIMeta || {},
    alias: callbackOauthe45Bju3UYIMeta?.alias || [],
    redirect: callbackOauthe45Bju3UYIMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/member/auth/callbackOauth.vue").then(m => m.default || m)
  },
  {
    name: completejUJbCWROJgMeta?.name ?? "member-join-complete",
    path: completejUJbCWROJgMeta?.path ?? "/member/join/complete",
    meta: completejUJbCWROJgMeta || {},
    alias: completejUJbCWROJgMeta?.alias || [],
    redirect: completejUJbCWROJgMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/member/join/complete.vue").then(m => m.default || m)
  },
  {
    name: email3WBaeBCYzvMeta?.name ?? "member-join-email",
    path: email3WBaeBCYzvMeta?.path ?? "/member/join/email",
    meta: email3WBaeBCYzvMeta || {},
    alias: email3WBaeBCYzvMeta?.alias || [],
    redirect: email3WBaeBCYzvMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/member/join/email.vue").then(m => m.default || m)
  },
  {
    name: index3G4GzuS7Q8Meta?.name ?? "member-join",
    path: index3G4GzuS7Q8Meta?.path ?? "/member/join",
    meta: index3G4GzuS7Q8Meta || {},
    alias: index3G4GzuS7Q8Meta?.alias || [],
    redirect: index3G4GzuS7Q8Meta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/member/join/index.vue").then(m => m.default || m)
  },
  {
    name: profilezrI4Vl0VtWMeta?.name ?? "member-join-profile",
    path: profilezrI4Vl0VtWMeta?.path ?? "/member/join/profile",
    meta: profilezrI4Vl0VtWMeta || {},
    alias: profilezrI4Vl0VtWMeta?.alias || [],
    redirect: profilezrI4Vl0VtWMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/member/join/profile.vue").then(m => m.default || m)
  },
  {
    name: emailJocZfm51YIMeta?.name ?? "member-login-email",
    path: emailJocZfm51YIMeta?.path ?? "/member/login/email",
    meta: emailJocZfm51YIMeta || {},
    alias: emailJocZfm51YIMeta?.alias || [],
    redirect: emailJocZfm51YIMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/member/login/email.vue").then(m => m.default || m)
  },
  {
    name: indexuY4i9eeyHpMeta?.name ?? "member-login",
    path: indexuY4i9eeyHpMeta?.path ?? "/member/login",
    meta: indexuY4i9eeyHpMeta || {},
    alias: indexuY4i9eeyHpMeta?.alias || [],
    redirect: indexuY4i9eeyHpMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/member/login/index.vue").then(m => m.default || m)
  },
  {
    name: index6RKmWJZkFaMeta?.name ?? "mobile-category",
    path: index6RKmWJZkFaMeta?.path ?? "/mobile/category",
    meta: index6RKmWJZkFaMeta || {},
    alias: index6RKmWJZkFaMeta?.alias || [],
    redirect: index6RKmWJZkFaMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/mobile/category/index.vue").then(m => m.default || m)
  },
  {
    name: contentstRdDtcxSf7Meta?.name ?? "mypage-business-analytics-contents",
    path: contentstRdDtcxSf7Meta?.path ?? "/mypage/business/analytics/contents",
    meta: contentstRdDtcxSf7Meta || {},
    alias: contentstRdDtcxSf7Meta?.alias || [],
    redirect: contentstRdDtcxSf7Meta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/mypage/business/analytics/contents.vue").then(m => m.default || m)
  },
  {
    name: outline11iBCEwuPRMeta?.name ?? "mypage-business-analytics-outline",
    path: outline11iBCEwuPRMeta?.path ?? "/mypage/business/analytics/outline",
    meta: outline11iBCEwuPRMeta || {},
    alias: outline11iBCEwuPRMeta?.alias || [],
    redirect: outline11iBCEwuPRMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/mypage/business/analytics/outline.vue").then(m => m.default || m)
  },
  {
    name: indexjOkFAE8lztMeta?.name ?? "mypage-business",
    path: indexjOkFAE8lztMeta?.path ?? "/mypage/business",
    meta: indexjOkFAE8lztMeta || {},
    alias: indexjOkFAE8lztMeta?.alias || [],
    redirect: indexjOkFAE8lztMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/mypage/business/index.vue").then(m => m.default || m)
  },
  {
    name: profileE9R8EI1y3zMeta?.name ?? "mypage-business-profile",
    path: profileE9R8EI1y3zMeta?.path ?? "/mypage/business/profile",
    meta: profileE9R8EI1y3zMeta || {},
    alias: profileE9R8EI1y3zMeta?.alias || [],
    redirect: profileE9R8EI1y3zMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/mypage/business/profile.vue").then(m => m.default || m)
  },
  {
    name: registerxaERyz8R2ZMeta?.name ?? "mypage-content-register",
    path: registerxaERyz8R2ZMeta?.path ?? "/mypage/content/register",
    meta: registerxaERyz8R2ZMeta || {},
    alias: registerxaERyz8R2ZMeta?.alias || [],
    redirect: registerxaERyz8R2ZMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/mypage/content/register.vue").then(m => m.default || m)
  },
  {
    name: indexk9dOmH8ZbmMeta?.name ?? "mypage",
    path: indexk9dOmH8ZbmMeta?.path ?? "/mypage",
    meta: indexk9dOmH8ZbmMeta || {},
    alias: indexk9dOmH8ZbmMeta?.alias || [],
    redirect: indexk9dOmH8ZbmMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/mypage/index.vue").then(m => m.default || m)
  },
  {
    name: registerWl36uiCJ8BMeta?.name ?? "mypage-media-publish-register",
    path: registerWl36uiCJ8BMeta?.path ?? "/mypage/media-publish/register",
    meta: registerWl36uiCJ8BMeta || {},
    alias: registerWl36uiCJ8BMeta?.alias || [],
    redirect: registerWl36uiCJ8BMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/mypage/media-publish/register.vue").then(m => m.default || m)
  },
  {
    name: changeKpASDC9fdkMeta?.name ?? "mypage-password-change",
    path: changeKpASDC9fdkMeta?.path ?? "/mypage/password/change",
    meta: changeKpASDC9fdkMeta || {},
    alias: changeKpASDC9fdkMeta?.alias || [],
    redirect: changeKpASDC9fdkMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/mypage/password/change.vue").then(m => m.default || m)
  },
  {
    name: check2gtCSHO5naMeta?.name ?? "mypage-password-check",
    path: check2gtCSHO5naMeta?.path ?? "/mypage/password/check",
    meta: check2gtCSHO5naMeta || {},
    alias: check2gtCSHO5naMeta?.alias || [],
    redirect: check2gtCSHO5naMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/mypage/password/check.vue").then(m => m.default || m)
  },
  {
    name: editcYVxQMm4HOMeta?.name ?? "mypage-user-edit",
    path: editcYVxQMm4HOMeta?.path ?? "/mypage/user/edit",
    meta: editcYVxQMm4HOMeta || {},
    alias: editcYVxQMm4HOMeta?.alias || [],
    redirect: editcYVxQMm4HOMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/mypage/user/edit.vue").then(m => m.default || m)
  },
  {
    name: withdrawAWAiRg5iHuMeta?.name ?? "mypage-user-withdraw",
    path: withdrawAWAiRg5iHuMeta?.path ?? "/mypage/user/withdraw",
    meta: withdrawAWAiRg5iHuMeta || {},
    alias: withdrawAWAiRg5iHuMeta?.alias || [],
    redirect: withdrawAWAiRg5iHuMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/mypage/user/withdraw.vue").then(m => m.default || m)
  },
  {
    name: emailG8KSaNeTfIMeta?.name ?? "password-find-email",
    path: emailG8KSaNeTfIMeta?.path ?? "/password/find/email",
    meta: emailG8KSaNeTfIMeta || {},
    alias: emailG8KSaNeTfIMeta?.alias || [],
    redirect: emailG8KSaNeTfIMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/password/find/email.vue").then(m => m.default || m)
  },
  {
    name: optionshLLTmiVxcXMeta?.name ?? "password-find-options",
    path: optionshLLTmiVxcXMeta?.path ?? "/password/find/options",
    meta: optionshLLTmiVxcXMeta || {},
    alias: optionshLLTmiVxcXMeta?.alias || [],
    redirect: optionshLLTmiVxcXMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/password/find/options.vue").then(m => m.default || m)
  },
  {
    name: phoneK6ny4Y637NMeta?.name ?? "password-find-phone",
    path: phoneK6ny4Y637NMeta?.path ?? "/password/find/phone",
    meta: phoneK6ny4Y637NMeta || {},
    alias: phoneK6ny4Y637NMeta?.alias || [],
    redirect: phoneK6ny4Y637NMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/password/find/phone.vue").then(m => m.default || m)
  },
  {
    name: _91type_933AjJOJTOeAMeta?.name ?? "password-reset-type",
    path: _91type_933AjJOJTOeAMeta?.path ?? "/password/reset/:type()",
    meta: _91type_933AjJOJTOeAMeta || {},
    alias: _91type_933AjJOJTOeAMeta?.alias || [],
    redirect: _91type_933AjJOJTOeAMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/password/reset/[type].vue").then(m => m.default || m)
  },
  {
    name: _91id_939L3NiTRXZeMeta?.name ?? "post-id",
    path: _91id_939L3NiTRXZeMeta?.path ?? "/post/:id()",
    meta: _91id_939L3NiTRXZeMeta || {},
    alias: _91id_939L3NiTRXZeMeta?.alias || [],
    redirect: _91id_939L3NiTRXZeMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/post/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93iZ4gMTjYLVMeta?.name ?? "post-category-list-id",
    path: _91id_93iZ4gMTjYLVMeta?.path ?? "/post/category/list/:id()",
    meta: _91id_93iZ4gMTjYLVMeta || {},
    alias: _91id_93iZ4gMTjYLVMeta?.alias || [],
    redirect: _91id_93iZ4gMTjYLVMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/post/category/list/[id].vue").then(m => m.default || m)
  },
  {
    name: listnbBQuCXzQaMeta?.name ?? "post-interest-list",
    path: listnbBQuCXzQaMeta?.path ?? "/post/interest/list",
    meta: listnbBQuCXzQaMeta || {},
    alias: listnbBQuCXzQaMeta?.alias || [],
    redirect: listnbBQuCXzQaMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/post/interest/list.vue").then(m => m.default || m)
  },
  {
    name: listTtUJ7hEbliMeta?.name ?? "post-latest-list",
    path: listTtUJ7hEbliMeta?.path ?? "/post/latest/list",
    meta: listTtUJ7hEbliMeta || {},
    alias: listTtUJ7hEbliMeta?.alias || [],
    redirect: listTtUJ7hEbliMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/post/latest/list.vue").then(m => m.default || m)
  },
  {
    name: listfZ5IM3tGxWMeta?.name ?? "post-popular-list",
    path: listfZ5IM3tGxWMeta?.path ?? "/post/popular/list",
    meta: listfZ5IM3tGxWMeta || {},
    alias: listfZ5IM3tGxWMeta?.alias || [],
    redirect: listfZ5IM3tGxWMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/post/popular/list.vue").then(m => m.default || m)
  },
  {
    name: _91id_93nZSqGqFPGPMeta?.name ?? "post-recommend-list-id",
    path: _91id_93nZSqGqFPGPMeta?.path ?? "/post/recommend/list/:id()",
    meta: _91id_93nZSqGqFPGPMeta || {},
    alias: _91id_93nZSqGqFPGPMeta?.alias || [],
    redirect: _91id_93nZSqGqFPGPMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/post/recommend/list/[id].vue").then(m => m.default || m)
  },
  {
    name: listVaJe10HzxGMeta?.name ?? "post-report-list",
    path: listVaJe10HzxGMeta?.path ?? "/post/report/list",
    meta: listVaJe10HzxGMeta || {},
    alias: listVaJe10HzxGMeta?.alias || [],
    redirect: listVaJe10HzxGMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/post/report/list.vue").then(m => m.default || m)
  },
  {
    name: chartFdxWoVOXvUMeta?.name ?? "sample-chart",
    path: chartFdxWoVOXvUMeta?.path ?? "/sample/chart",
    meta: chartFdxWoVOXvUMeta || {},
    alias: chartFdxWoVOXvUMeta?.alias || [],
    redirect: chartFdxWoVOXvUMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/sample/chart.vue").then(m => m.default || m)
  },
  {
    name: modalUG4QBxy0CjMeta?.name ?? "sample-modal",
    path: modalUG4QBxy0CjMeta?.path ?? "/sample/modal",
    meta: modalUG4QBxy0CjMeta || {},
    alias: modalUG4QBxy0CjMeta?.alias || [],
    redirect: modalUG4QBxy0CjMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/sample/modal.vue").then(m => m.default || m)
  },
  {
    name: page1ljFJjkFSzMMeta?.name ?? "sample-page1",
    path: page1ljFJjkFSzMMeta?.path ?? "/sample/page1",
    meta: page1ljFJjkFSzMMeta || {},
    alias: page1ljFJjkFSzMMeta?.alias || [],
    redirect: page1ljFJjkFSzMMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/sample/page1.vue").then(m => m.default || m)
  },
  {
    name: page26FxOgkzU0CMeta?.name ?? "sample-page2",
    path: page26FxOgkzU0CMeta?.path ?? "/sample/page2",
    meta: page26FxOgkzU0CMeta || {},
    alias: page26FxOgkzU0CMeta?.alias || [],
    redirect: page26FxOgkzU0CMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/sample/page2.vue").then(m => m.default || m)
  },
  {
    name: page4_errorWuwXEWLKovMeta?.name ?? "sample-page4_error",
    path: page4_errorWuwXEWLKovMeta?.path ?? "/sample/page4_error",
    meta: page4_errorWuwXEWLKovMeta || {},
    alias: page4_errorWuwXEWLKovMeta?.alias || [],
    redirect: page4_errorWuwXEWLKovMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/sample/page4_error.vue").then(m => m.default || m)
  },
  {
    name: unsplashH3F4iIfKf4Meta?.name ?? "sample-unsplash",
    path: unsplashH3F4iIfKf4Meta?.path ?? "/sample/unsplash",
    meta: unsplashH3F4iIfKf4Meta || {},
    alias: unsplashH3F4iIfKf4Meta?.alias || [],
    redirect: unsplashH3F4iIfKf4Meta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/sample/unsplash.vue").then(m => m.default || m)
  },
  {
    name: searcht3wAo3yyVxMeta?.name ?? "search",
    path: searcht3wAo3yyVxMeta?.path ?? "/search",
    meta: searcht3wAo3yyVxMeta || {},
    alias: searcht3wAo3yyVxMeta?.alias || [],
    redirect: searcht3wAo3yyVxMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/search.vue").then(m => m.default || m),
    children: [
  {
    name: business5HmZ09vJONMeta?.name ?? "search-business",
    path: business5HmZ09vJONMeta?.path ?? "business",
    meta: business5HmZ09vJONMeta || {},
    alias: business5HmZ09vJONMeta?.alias || [],
    redirect: business5HmZ09vJONMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/search/business.vue").then(m => m.default || m)
  },
  {
    name: contentsn5jIdKkuZgMeta?.name ?? "search-contents",
    path: contentsn5jIdKkuZgMeta?.path ?? "contents",
    meta: contentsn5jIdKkuZgMeta || {},
    alias: contentsn5jIdKkuZgMeta?.alias || [],
    redirect: contentsn5jIdKkuZgMeta?.redirect,
    component: () => import("/apps/src/ound-front/source/apps/shop-web/pages/search/contents.vue").then(m => m.default || m)
  }
]
  }
]
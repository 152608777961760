import revive_payload_client_4sVQNw7RlN from "/apps/src/ound-front/source/apps/shop-web/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/apps/src/ound-front/source/apps/shop-web/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/apps/src/ound-front/source/apps/shop-web/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/apps/src/ound-front/source/apps/shop-web/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/apps/src/ound-front/source/apps/shop-web/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/apps/src/ound-front/source/apps/shop-web/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/apps/src/ound-front/source/apps/shop-web/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/apps/src/ound-front/source/apps/shop-web/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/apps/src/ound-front/source/apps/shop-web/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/apps/src/ound-front/source/apps/shop-web/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import directives_8CcCirWtnE from "/apps/src/ound-front/source/apps/shop-web/plugins/directives.ts";
import error_handler_kEP5FliEXj from "/apps/src/ound-front/source/apps/shop-web/plugins/error-handler.ts";
import loading_GxQ2Z9Goxa from "/apps/src/ound-front/source/apps/shop-web/plugins/loading.ts";
import runtime_hook_S6AOVuojr3 from "/apps/src/ound-front/source/apps/shop-web/plugins/runtime-hook.ts";
import tms_CFB4eXo5vC from "/apps/src/ound-front/source/apps/shop-web/plugins/tms.ts";
import vue_date_picker_AkrrI4qPP9 from "/apps/src/ound-front/source/apps/shop-web/plugins/vue-date-picker.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  directives_8CcCirWtnE,
  error_handler_kEP5FliEXj,
  loading_GxQ2Z9Goxa,
  runtime_hook_S6AOVuojr3,
  tms_CFB4eXo5vC,
  vue_date_picker_AkrrI4qPP9
]